import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import {Collapse} from 'antd';
import {Trans, t} from '@lingui/macro';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
const Panel = Collapse.Panel;

/**
 * @fero
 */

class ColumnFilterLayout extends React.PureComponent {
    static propTypes = {
        filters: PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.node,
                    selector: PropTypes.node.isRequired,
                    rightsFrom: PropTypes.number,
                    rightsTo: PropTypes.number,
                }).isRequired
            ).isRequired
        ).isRequired,
        collapsible: PropTypes.bool,
        defaultCollapsed: PropTypes.bool,
        columnClassName: PropTypes.string,
        [SESSION_ATTRIBUTES.IS_MOBILE]: PropTypes.bool,
    };

    static defaultProps = {
        collapsible: true,
        defaultCollapsed: false,
    };

    render() {
        const {filters, collapsible, columnClassName, defaultCollapsed, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile} = this.props;
        const content = <ColumnFilterContent filters={filters} columnClassName={columnClassName}/>;
        return collapsible ? <div className="full-size-width">
            <Collapse defaultActiveKey={isMobile || defaultCollapsed ? null : ['filters']}>
                <Panel header={<Trans>Filtre</Trans>} key="filters">
                    {content}
                </Panel>
            </Collapse>
        </div> : 
        content;
    }
}

class ColumnFilterContent extends React.PureComponent {
    static propTypes = {
        filters: PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.node,
                    selector: PropTypes.node.isRequired,
                    rightsFrom: PropTypes.number,
                    rightsTo: PropTypes.number,
                }).isRequired
            ).isRequired
        ).isRequired,
        columnClassName: PropTypes.string,
    };

    static defaultProps = {
        columnClassName: 'column-filter-group',
    };

    render() {
        const {filters, columnClassName} = this.props;
        return <div className="d-flex flex-wrap">
            {filters.map((filterColumn, index) => {
                return <div className={"mx-1 " + columnClassName} key={index}>
                    {filterColumn.map((filter, index) => {
                        return <RightsWrapper key={index} from={filter.rightsFrom} to={filter.rightsTo}>
                            <div className="p-1 column-filter">
                                {filter.label != null ? 
                                    <div className="pr-2 column-filter-label">
                                        {filter.label}
                                    </div> : null 
                                } 
                                <div className={filter.label != null ? "column-filter-selector" : "full-size-width"}>
                                    {filter.selector}
                                </div>
                            </div>
                        </RightsWrapper>
                    })}
                </div>
            })}
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(ColumnFilterLayout);