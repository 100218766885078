import React from 'react';
import { Location, CustomerDetails } from '../../constants/propTypesDefinitions';
import CartOrderInfoPageForm from './cartOrderInfo/CartOrderInfoPageForm';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
import locationHOC from '../locationProvider/locationHOC';
import { QUERY_PARAMS } from '../../constants/navigation';
import { getQueryParamNumber, navigateToParametrized, getQueryParam } from '../../lib/url';
import { removeUndefinedAttributes } from '../../lib/object';
import { TRADE } from '../../constants/trade';

/**
 * @fero
 */

class CartOrderInfo extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: CustomerDetails.isRequired,
    };

    onChangeParams = (params) => {
        const {location} = this.props;
        let queryParams = {
            [QUERY_PARAMS.ID_USER]: params.userId,
            [QUERY_PARAMS.ID_INVOICE_ADDRESS]: params.invoiceAddressId,
            [QUERY_PARAMS.ID_DELIVERY_ADDRESS]: params.deliveryAddressId,
            [QUERY_PARAMS.ORDER_FORM_TRADE]: params.trade,
            [QUERY_PARAMS.ID_TRANSPORT_TYPE]: params.transportTypeId,
            [QUERY_PARAMS.ID_PAYMENT_METHOD]: params.paymentMethodId,
            [QUERY_PARAMS.DATE_REQUESTED]: params.dateRequested,
            [QUERY_PARAMS.CUSTOMER_REFERENCE]: params.customerReference,
            [QUERY_PARAMS.CUSTOMER_NOTICES]: params.customerNotices,
            [QUERY_PARAMS.DEALER_NOTICES]: params.dealerNotices,
            [QUERY_PARAMS.ORDER_FORM_IS_DIVISIBLE]: params.isDivisible,
            [QUERY_PARAMS.ORDER_FORM_IS_CRITICAL]: params.isCritical,
            [QUERY_PARAMS.ORDER_FORM_ID_DST_STOCK]: params.dstStockId,
            [QUERY_PARAMS.ORDER_FORM_PROXY]: params.isProxy,
            [QUERY_PARAMS.FINAL_ORDER_REFERENCE]: params.finalOrderRef,
            [QUERY_PARAMS.FINAL_CUSTOMER_NAME]: params.finalCustomerName,
            [QUERY_PARAMS.FINAL_USER_FULLNAME]: params.finalUserName,
            [QUERY_PARAMS.FINAL_USER_EMAIL]: params.finalUserEmail,
            [QUERY_PARAMS.FINAL_USER_PHONE]: params.finalUserPhone,
            [QUERY_PARAMS.FINAL_USER_LANGUAGE]: params.finalUserLanguage,
            [QUERY_PARAMS.FINAL_ADDRESS_STREET]: params.finalAddressStreet,
            [QUERY_PARAMS.FINAL_ADDRESS_ZIP_CODE]: params.finalAddressZipCode,
            [QUERY_PARAMS.FINAL_ADDRESS_CITY]: params.finalAddressCity,
            [QUERY_PARAMS.FINAL_ADDRESS_COUNTRY]: params.finalAddressCountryId,
        };
        removeUndefinedAttributes(queryParams);
        navigateToParametrized(location, null, queryParams);
    };

    getParams = () => {
        const {location} = this.props;
        return {
            userId: getQueryParamNumber(location, QUERY_PARAMS.ID_USER),
            invoiceAddressId: getQueryParamNumber(location, QUERY_PARAMS.ID_INVOICE_ADDRESS),
            deliveryAddressId: getQueryParamNumber(location, QUERY_PARAMS.ID_DELIVERY_ADDRESS),
            trade: getQueryParam(location, QUERY_PARAMS.ORDER_FORM_TRADE),
            transportTypeId: getQueryParamNumber(location, QUERY_PARAMS.ID_TRANSPORT_TYPE),
            paymentMethodId: getQueryParamNumber(location, QUERY_PARAMS.ID_PAYMENT_METHOD),
            customerReference: getQueryParam(location, QUERY_PARAMS.CUSTOMER_REFERENCE),
            customerNotices: getQueryParam(location, QUERY_PARAMS.CUSTOMER_NOTICES),
            dealerNotices: getQueryParam(location, QUERY_PARAMS.DEALER_NOTICES),
            isDivisible: getQueryParamNumber(location, QUERY_PARAMS.ORDER_FORM_IS_DIVISIBLE),
            isCritical: getQueryParamNumber(location, QUERY_PARAMS.ORDER_FORM_IS_CRITICAL),
            dstStockId: getQueryParamNumber(location, QUERY_PARAMS.ORDER_FORM_ID_DST_STOCK),
            isProxy: getQueryParamNumber(location, QUERY_PARAMS.ORDER_FORM_PROXY),
            finalOrderRef: getQueryParam(location, QUERY_PARAMS.FINAL_ORDER_REFERENCE),
            finalCustomerName: getQueryParam(location, QUERY_PARAMS.FINAL_CUSTOMER_NAME),
            finalUserName: getQueryParam(location, QUERY_PARAMS.FINAL_USER_FULLNAME),
            finalUserEmail: getQueryParam(location, QUERY_PARAMS.FINAL_USER_EMAIL),
            finalUserPhone: getQueryParam(location, QUERY_PARAMS.FINAL_USER_PHONE),
            finalUserLanguage: getQueryParam(location, QUERY_PARAMS.FINAL_USER_LANGUAGE),
            finalAddressStreet: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_STREET),
            finalAddressZipCode: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_ZIP_CODE),
            finalAddressCity: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_CITY),
            finalAddressCountryId: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_COUNTRY),
        };
    };


    render() {
        const {[GLOBAL_DATA.CART_CUSTOMER_DETAILS]: customerDetails} = this.props;
        return <CartOrderInfoPageForm
            customerDetails={customerDetails}
            onChangeParams={this.onChangeParams}
            params={this.getParams()}
            allowProxy={true}
        />;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.CART_CUSTOMER_DETAILS])(CartOrderInfo));