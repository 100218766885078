import React from 'react';
import PropTypes from 'prop-types';
import { PUBLIC_DIR } from '../../constants/apiEndpoints';
import {getDomain} from '../../lib/url';
import { withPrefix } from 'gatsby';

/**
 * @fero
 */

class Image extends React.PureComponent {
	static propTypes = {
        src: PropTypes.string,
        className: PropTypes.string,
        wrapperClassName: PropTypes.string
    };

    static defaultProps = {
        className: '',
    };

    render() {
    	const {className, wrapperClassName, src, ...props} = this.props;
        const placeholder = withPrefix("/images/placeholder.png");
    	const urlChecked = src != null && src != "" ? (getDomain() + PUBLIC_DIR + src) : placeholder;
        return <div className={'d-flex justify-content-center' + (wrapperClassName ? (' ' + wrapperClassName) : '')}>
            <img
                className={className}
                {...props}
                src={urlChecked}
                onError={(ev) => {
                    ev.target.onerror = null;
                    ev.target.src=placeholder;
                }}
            />
        </div>;
    }

}

export default Image;